<template>
  <v-form
    ref="form"
    v-model="valid"
    lazy-validation
    @submit.prevent="submit()">
    <v-container>
      <Loading
        v-if="loading"
      />
      <v-card
        class="px-3 ma-4"
        outlined>
        <v-row class="card-header">
          <v-col cols="12">
            <h2>
              {{ $t('register.personalInformation') }}
            </h2>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <v-text-field
              v-model="customerInfo.firstname"
              :rules="firstnameRules"
              :label="`${ $t('global.name')} (${ $t('global.require') })`" />
            <v-text-field
              v-model="customerInfo.lastname"
              :rules="lastnameRules"
              :label="`${ $t('global.lastname')} (${ $t('global.require') })`" />
            <v-text-field
              v-model="customerInfo.nickname"
              :label="`${ $t('global.nickname')} (${ $t('global.optional') })`" />
            <v-text-field
              v-model="customerInfo.carLicensePlate"
              :label="`${ $t('global.carLicensePlate')} (${ $t('global.optional') })`" />
            <v-select
              v-model="customerInfo.sex"
              :items="sexItems"
              item-text="text"
              item-value="value"
              :rules="sexRules"
              :label="`${ $t('global.sex')} (${ $t('global.require') })`" />
            <p class="font-weight-light">
              {{ $t('global.birthDate') }} ({{ $t('global.require') }})
            </p>
            <DateDropDown
              :birthday="true"
              @input="handleDateDropDown($event)" />
            <v-text-field
              v-model="customerInfo.tel"
              type="tel"
              :rules="telRules"
              :label="`${ $t('global.tel')} (${ $t('global.require') })`"
              maxlength="10"
              @keypress="$keypressNumber()" />
            <v-text-field
              v-model="customerInfo.email"
              type="email"
              :rules="emailRules"
              :label="`${ $t('global.email')} (${ $t('global.require') })`" />
            <v-autocomplete
              v-model="customerInfo.nationality"
              :rules="notEmpytyRules"
              :items="nationalityList"
              :placeholder="`${ $t('global.nationality')} (${ $t('global.require') })`"
              auto-select-first
            >
              <template #no-data>
                <span>{{ $t('global.notFound') }}</span>
              </template>
            </v-autocomplete>
            <div v-if="$i18n.locale === 'th'">
              <AddressField
                v-model="customerInfo.address.subDistrict"
                :rules="notEmpytyRules"
                :label="`${ $t('global.subDistrict')} (${ $t('global.require') })`"
                type="sub-district"
                @select="onSelectAddress($event)" />
              <AddressField
                v-model="customerInfo.address.district"
                :rules="notEmpytyRules"
                :label="`${ $t('global.district')} (${ $t('global.require') })`"
                type="district"
                @select="onSelectAddress($event)" />
              <AddressField
                v-model="customerInfo.address.province"
                :rules="notEmpytyRules"
                :label="`${ $t('global.province')} (${ $t('global.require') })`"
                type="province"
                @select="onSelectAddress($event)" />
              <AddressField
                v-model="customerInfo.address.postcode"
                :rules="notEmpytyRules"
                :label="`${ $t('global.zipCode')} (${ $t('global.require') })`"
                type="postcode"
                @select="onSelectAddress($event)" />
            </div>
            <div v-if="$i18n.locale === 'en'">
              <v-combobox
                v-model="customerInfo.address.province"
                :items="province"
                item-text="text"
                item-value="value"
                :no-data-text="$t('global.notFound')"
                :rules="notEmpytyRules"
                :label="`${ $t('global.province')} (${ $t('global.require') })`"
              />
              <v-text-field
                v-model="customerInfo.address.district"
                :rules="notEmpytyRules"
                :label="`${ $t('global.district')} (${ $t('global.require') })`"
              />
              <v-text-field
                v-model="customerInfo.address.subDistrict"
                :rules="notEmpytyRules"
                :label="`${ $t('global.subDistrict')} (${ $t('global.require') })`"
              />
              <v-text-field
                v-model="customerInfo.address.postcode"
                :rules="notEmpytyRules"
                :label="`${ $t('global.zipCode')} (${ $t('global.require') })`"
              />
            </div>
          </v-col>
        </v-row>
      </v-card>
      <v-card
        v-for="(kidinfo, index) in kidsinfo"
        :key="index"
        class="px-3 ma-4">
        <v-row class="card-header">
          <v-col cols="12">
            <h2>
              {{ $t('register.participantTitle') }} {{ index + 1 }}
            </h2>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <v-text-field
              v-model="kidsinfo[index].firstname"
              :rules="[rules.required]"
              :label="`${ $t('global.name') }`" />
            <v-text-field
              v-model="kidsinfo[index].lastname"
              :rules="[rules.required]"
              :label="`${ $t('global.lastname') }`" />
            <v-text-field
              v-model="kidsinfo[index].nickname"
              :label="`${ $t('global.nickname') }`" />
            <v-select
              v-model="kidsinfo[index].sex"
              :items="sexItems"
              item-text="text"
              item-value="value"
              :rules="[rules.required]"
              :label="`${ $t('global.sex') }`" />
            <!-- <v-select
              v-model="kidsinfo[index].ageGroup"
              :items="ageItems"
              :rules="[rules.required]"
              :label="`${ $t('global.type') }`" /> -->
            <p class="font-weight-light">
              {{ $t('global.birthDate') }}
            </p>
            <DateDropDown @input="handleDateDropDown($event, index, 'kid')" />
            <v-textarea
              v-model="kidsinfo[index].note"
              :label="`${ $t('global.note') } (${ $t('global.foodAllergy') } ${ $t('global.or') } ${ $t('global.congenitalDisease') } )`"
              rows="2"></v-textarea>
          </v-col>
        </v-row>
      </v-card>
      <div class="ma-4">
        <v-row justify="center">
          <v-col cols="12">
            <v-btn
              type="submit"
              block
              color="success"
              :loading="loading"
              depressed
            >
              {{ $t('global.submit') }}
            </v-btn>
          </v-col>
        </v-row>
      </div>
    </v-container>
  </v-form>
</template>

<script>
import liff from '@line/liff'
import { mapActions, mapGetters } from 'vuex'
import Loading from '@/components/Loading.vue'
import DateDropDown from '@/components/DateDropDown.vue'
import AddressField from '@/components/AddressField.vue'
import nationalityJson from '../../../nationality.json'

export default {
  components: {
    AddressField,
    Loading,
    DateDropDown
  },

  data () {
    return {
      loading: false,
      customerInfo: {
        firstname: '',
        lastname: '',
        sex: '',
        birthday: this.$dayjs().format('YYYY-MM-DD'),
        email: '',
        tel: '',
        nationality: '',
        childrenQty: 0,
        address: {
          subDistrict: '',
          district: '',
          province: '',
          postcode: ''
        }
      },
      birthday: [],
      kidsinfo: [],
      lineUid: '',
      valid: false,
      activePicker: null,
      bridthdayDate: null,
      menu: false
    }
  },
  computed: {
    province () {
      return [{
  text: 'Bangkok',
  value: 'Bangkok'
}, {
  text: 'Samut Prakan',
  value: 'Samut Prakan'
}, {
  text: 'Nonthaburi',
  value: 'Nonthaburi'
}, {
  text: 'Pathum Thani',
  value: 'Pathum Thani'
}, {
  text: 'Phra Nakhon Si Ayutthaya',
  value: 'Phra Nakhon Si Ayutthaya'
}, {
  text: 'Ang Thong',
  value: 'Ang Thong'
}, {
  text: 'Loburi',
  value: 'Loburi'
}, {
  text: 'Sing Buri',
  value: 'Sing Buri'
}, {
  text: 'Chai Nat',
  value: 'Chai Nat'
}, {
  text: 'Saraburi',
  value: 'Saraburi'
}, {
  text: 'Chon Buri',
  value: 'Chon Buri'
}, {
  text: 'Rayong',
  value: 'Rayong'
}, {
  text: 'Chanthaburi',
  value: 'Chanthaburi'
}, {
  text: 'Trat',
  value: 'Trat'
}, {
  text: 'Chachoengsao',
  value: 'Chachoengsao'
}, {
  text: 'Prachin Buri',
  value: 'Prachin Buri'
}, {
  text: 'Nakhon Nayok',
  value: 'Nakhon Nayok'
}, {
  text: 'Sa Kaeo',
  value: 'Sa Kaeo'
}, {
  text: 'Nakhon Ratchasima',
  value: 'Nakhon Ratchasima'
}, {
  text: 'Buri Ram',
  value: 'Buri Ram'
}, {
  text: 'Surin',
  value: 'Surin'
}, {
  text: 'Si Sa Ket',
  value: 'Si Sa Ket'
}, {
  text: 'Ubon Ratchathani',
  value: 'Ubon Ratchathani'
}, {
  text: 'Yasothon',
  value: 'Yasothon'
}, {
  text: 'Chaiyaphum',
  value: 'Chaiyaphum'
}, {
  text: 'Amnat Charoen',
  value: 'Amnat Charoen'
}, {
  text: 'Nong Bua Lam Phu',
  value: 'Nong Bua Lam Phu'
}, {
  text: 'Khon Kaen',
  value: 'Khon Kaen'
}, {
  text: 'Udon Thani',
  value: 'Udon Thani'
}, {
  text: 'Loei',
  value: 'Loei'
}, {
  text: 'Nong Khai',
  value: 'Nong Khai'
}, {
  text: 'Maha Sarakham',
  value: 'Maha Sarakham'
}, {
  text: 'Roi Et',
  value: 'Roi Et'
}, {
  text: 'Kalasin',
  value: 'Kalasin'
}, {
  text: 'Sakon Nakhon',
  value: 'Sakon Nakhon'
}, {
  text: 'Nakhon Phanom',
  value: 'Nakhon Phanom'
}, {
  text: 'Mukdahan',
  value: 'Mukdahan'
}, {
  text: 'Chiang Mai',
  value: 'Chiang Mai'
}, {
  text: 'Lamphun',
  value: 'Lamphun'
}, {
  text: 'Lampang',
  value: 'Lampang'
}, {
  text: 'Uttaradit',
  value: 'Uttaradit'
}, {
  text: 'Phrae',
  value: 'Phrae'
}, {
  text: 'Nan',
  value: 'Nan'
}, {
  text: 'Phayao',
  value: 'Phayao'
}, {
  text: 'Chiang Rai',
  value: 'Chiang Rai'
}, {
  text: 'Mae Hong Son',
  value: 'Mae Hong Son'
}, {
  text: 'Nakhon Sawan',
  value: 'Nakhon Sawan'
}, {
  text: 'Uthai Thani',
  value: 'Uthai Thani'
}, {
  text: 'Kamphaeng Phet',
  value: 'Kamphaeng Phet'
}, {
  text: 'Tak',
  value: 'Tak'
}, {
  text: 'Sukhothai',
  value: 'Sukhothai'
}, {
  text: 'Phitsanulok',
  value: 'Phitsanulok'
}, {
  text: 'Phichit',
  value: 'Phichit'
}, {
  text: 'Phetchabun',
  value: 'Phetchabun'
}, {
  text: 'Ratchaburi',
  value: 'Ratchaburi'
}, {
  text: 'Kanchanaburi',
  value: 'Kanchanaburi'
}, {
  text: 'Suphan Buri',
  value: 'Suphan Buri'
}, {
  text: 'Nakhon Pathom',
  value: 'Nakhon Pathom'
}, {
  text: 'Samut Sakhon',
  value: 'Samut Sakhon'
}, {
  text: 'Samut Songkhram',
  value: 'Samut Songkhram'
}, {
  text: 'Phetchaburi',
  value: 'Phetchaburi'
}, {
  text: 'Prachuap Khiri Khan',
  value: 'Prachuap Khiri Khan'
}, {
  text: 'Nakhon Si Thammarat',
  value: 'Nakhon Si Thammarat'
}, {
  text: 'Krabi',
  value: 'Krabi'
}, {
  text: 'Phangnga',
  value: 'Phangnga'
}, {
  text: 'Phuket',
  value: 'Phuket'
}, {
  text: 'Surat Thani',
  value: 'Surat Thani'
}, {
  text: 'Ranong',
  value: 'Ranong'
}, {
  text: 'Chumphon',
  value: 'Chumphon'
}, {
  text: 'Songkhla',
  value: 'Songkhla'
}, {
  text: 'Satun',
  value: 'Satun'
}, {
  text: 'Trang',
  value: 'Trang'
}, {
  text: 'Phatthalung',
  value: 'Phatthalung'
}, {
  text: 'Pattani',
  value: 'Pattani'
}, {
  text: 'Yala',
  value: 'Yala'
}, {
  text: 'Narathiwat',
  value: 'Narathiwat'
}, {
  text: 'buogkan',
  value: 'buogkan'
}, {
  text: '-',
  value: '-'
}]
    },
    nationalityList () {
      const language = this.$i18n.locale
      const list = []
      if (language === 'en') {
        nationalityJson.map((item) => list.push(item.enName))
      } else {
        nationalityJson.map((item) => list.push(item.name))
      }
      return list
    },
    sexItems () {
      return [
        { text: this.$t('global.men'), value: 'male' },
        { text: this.$t('global.women'), value: 'female' },
        { text: this.$t('global.undefine'), value: 'undefine' }
      ]
    },
    ageItems () {
      return [{ text: this.$t('global.adult'), value: 'Adult' }, { text: this.$t('global.child'), value: 'Young' }]
    },
    notEmpytyRules () {
      return [(v) => !!v || this.$t('global.validate')]
    },
    firstnameRules () {
      return [(v) => !!v || this.$t('global.validate')]
    },
    lastnameRules () {
      return [(v) => !!v || this.$t('global.validate')]
    },
    nicknameRules () {
      return [(v) => !!v || this.$t('global.validate')]
    },
    sexRules () {
      return [(v) => !!v || this.$t('global.validate')]
    },
    birthdayRules () {
      return [(v) => !!v || this.$t('global.validate')]
    },
    telRules () {
      return [(v) => !!v || this.$t('global.validate')]
    },
    emailRules () {
      return [
        (v) => !!v || this.$t('global.validateRules.email'),
        (v) => /.+@.+\..+/.test(v) || this.$t('global.validateRules.formatEmail')
      ]
    },
    childrenQtyRules () {
      return [
        (v) => v !== null || this.$t('global.validate'),
        (v) => v !== '' || this.$t('global.validate')
      ]
    },
    nationalityRules () {
      return [(v) => !!v || this.$t('global.validate')]
    },
    ...mapGetters({
      form: 'booking/form',
      group: 'bookingGroup/form',
      isFromGroup: 'bookingGroup/isFromGroup',
      isFromBookingNormal: 'booking/isFromBookingNormal',
      participants: 'booking/participants',
      lineUidStore: 'booking/lineUid'
    })
  },
  mounted () {
    this.checkLogined()
    if (this.isFromBookingNormal) {
      this.customerInfo.childrenQty = this.participants - 1
      this.addKidsInfo(this.customerInfo.childrenQty)
    } else {
      this.liffInit()
    }
  },
  methods: {
    ...mapActions({
      setForm: 'booking/setForm',
      setIsRegistered: 'booking/setIsRegistered',
      setLineUid: 'booking/setLineUid',
      setGroup: 'bookingGroup/setForm',
      setIsMember: 'booking/setIsMember'
    }),
    addKidsInfo (num) {
      this.kidsinfo = []
      for (let i = 0; i < Number(num); i++) {
        this.birthday.push({
          day: '',
          month: '',
          year: ''
        })
        this.kidsinfo.push({})
      }
    },
    async submit () {
      const valid = this.$refs.form.validate()
      if (valid) {
        try {
          this.loading = true
          const payload = {
            member: { ...this.customerInfo, lineUid: this.lineUidStore, language: this.$i18n.locale },
            children: this.kidsinfo
          }
          const { data: { data } } = await this.axios.post(`${process.env.VUE_APP_API_URL}/member?branch=${process.env.VUE_APP_BRANCH}`, payload)
          localStorage.setItem('member', JSON.stringify(data))

          if (this.isFromGroup) {
            this.setIsMember(true)
            this.setIsRegistered(true)
            this.$router.push(`/liff/booking/group/${this.group.branch}`)
          } else if (this.isFromBookingNormal) {
            const { form } = this
            form.memberId = data.member.memberId
            form.participant = [data.member, ...data.children]
            this.setForm(form)
            // this.setIsRegistered(true)
            await this.axios.post(`${process.env.VUE_APP_API_URL}/order`, this.form)
            this.$router.push('/liff/history')
          } else {
            this.$router.push('/liff/booking')
          }
        } catch (error) {
          this.showErrorMessage({
          type: 'warning',
          message: this.$t('global.checkValidate')
        })
        setTimeout(() => {
          this.closeErrorMessage()
        }, 4000)
        } finally {
          this.loading = false
        }
      }
    },
    async liffInit () {
      await liff.init({ liffId: process.env.VUE_APP_LIFF_ID })
      if (liff.isLoggedIn()) {
        const profile = await liff.getProfile()
        const { userId } = profile
        this.lineUid = userId
      } else {
        liff.login({ redirectUri: `${process.env.VUE_APP_FRONTEND_URL}/liff/register` })
      }
    },
    checkLogined () {
      if (localStorage.getItem('member')) {
        this.$router.push('/liff/booking')
      }
    },
    checkIsFromGroup () {
      if (this.isFromGroup) {
        this.customerInfo.firstname = this.group.member.firstname
        this.customerInfo.lastname = this.group.member.lastname
        this.customerInfo.tel = this.group.member.tel
      }
    },
    handleDateDropDown (event, index, type) {
      if (type === 'kid') {
        this.kidsinfo[index].birthday = event
      } else {
        this.customerInfo.birthday = event
      }
    },
    onSelectAddress (address) {
      this.customerInfo.address.subDistrict = address?.subDistrict || ''
      this.customerInfo.address.district = address?.district || ''
      this.customerInfo.address.province = address?.province || ''
      this.customerInfo.address.postcode = address?.postcode || ''
    }
  }
}
</script>

<style lang="scss" scoped>
.card-header {
  background-color: #88CFF5;
  color: #FFFFFF;
}
</style>
